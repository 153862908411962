import {styled} from 'styled-components';
import {mediaQueries} from '../../modules/common';

const ClockPlaceholder = styled.div`
    background-image: url(/static/landings/miscellanea/clock-desktop.webp);
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;

    ${mediaQueries.lessThan('small')`         
        background-image: url(/static/landings/miscellanea/clock-mobile.webp);
        background-position: center;
        background-size: unset;
        margin-left: unset;
    `}
`;

export {ClockPlaceholder};
