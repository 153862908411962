import {styled} from 'styled-components';
import {mediaQueries} from '../../modules/common';

const PiggyPlaceholder = styled.div`
    background-image: url(/static/landings/miscellanea/piggy-desktop.webp);
    background-repeat: no-repeat;
    background-position: 75% center;
    background-size: contain;
    width: 100%;
    height: 100%;

    ${mediaQueries.lessThan('small')`    
        background-image: url(/static/landings/miscellanea/piggy-mobile.webp);     
        background-position: center;
        background-size: unset;
    `}
`;

export {PiggyPlaceholder};
