import withTranslation from 'next-translate/withTranslation';
import React, {
    ChangeEventHandler,
    FocusEventHandler,
    useEffect,
    useState,
} from 'react';
import {styled} from 'styled-components';
import {withAnalytics} from '../modules/analytics/analytics';
import {mediaQueries, StyledProps, ThemedProps} from '../modules/common';
import {Wrapper} from './home-wrapper';
import {SERVICES} from '../modules/util/constants';
import {I18n} from '../modules/util/i18n/i18n-interface';
import {useValidation} from '../modules/util/use-validation';
import {getConnectHomeLandingURLLink} from '../modules/util/utilities/get-connect-url-link';
import {Validators} from '../modules/util/validators';
import {H1, H3} from './headings';
import {LoaderButton} from './loader-button';
import {TextAdornment, TextField} from './text-field';
import {AppScoreWidget} from './app-score-widget';
import {getInitialValueForRechargeInputFromURL} from '../modules/util/utilities/get-initial-value-for-recharge-input-from-url';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PhoneHomeSvg from '../public/static/landings/miscellanea/home-phone.svg';
import {HeaderNoise, GradientType} from './header-noise';
import {
    typographyBodyStyles,
    typographyDisplay2Styles,
    typographyHeading1Styles,
    typographyLargeBodyStyles,
    typographyTitleBodyStyles,
} from '../modules/common/styles';
import ReviewsIoRatingBarWidget from './reviews-io/reviews-io-rating-bar-widget';

interface HomeHeaderProps extends StyledProps {
    i18n: I18n;
    title: string;
    subtitle: string;
    labelText: string;
    buttonText: string;
    countryPrefix: number;
    mobilePrefixes: number[];
    cellphoneNumberLength: number;
    serviceName: string;
    serviceProviderName: string;
    operatorId: number;
    isLoggedIn: boolean;
}

const TitleSection = styled.div`
    max-width: 552px;
    margin-bottom: ${({theme}): string => theme.spacing.xxxxl};

    ${mediaQueries.lessThan('small')`    
        margin-bottom: ${({theme}: ThemedProps): string => theme.spacing.xl};
    `}
`;

const StyledH1 = styled(H1)`
    ${typographyDisplay2Styles}
    text-align: left;
    margin: 0;
    margin-bottom: ${({theme}): string => theme.spacing.m};

    ${mediaQueries.lessThan('small')`         
        ${typographyHeading1Styles}
        text-align: center;
        
        margin-bottom: ${({theme}: ThemedProps): string => theme.spacing.l};
    `}
`;

const StyledH3 = styled(H3)`
    ${typographyLargeBodyStyles}
    text-align: left;
    margin: 0;

    ${mediaQueries.lessThan('small')`         
        ${typographyBodyStyles}
        text-align: center;
    `}
`;

const Label = styled.div`
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${({theme}) => theme.colors.ballena};

    margin-bottom: ${({theme}): string => theme.spacing.xs};
`;

const Div = styled.div`
    display: flex;
    justify-content: space-between;

    ${mediaQueries.lessThan('small')`         
        flex-direction: column;   
        margin-bottom: 0;
    `}
`;

export const SendButton = styled(LoaderButton)`
    ${typographyTitleBodyStyles}
    color: ${({theme}): string => theme.colors.arroz};

    min-width: 125px;
    max-height: 48px;

    ${mediaQueries.lessThan('small')`      
        margin-top: ${({theme}: ThemedProps): string => theme.spacing.m};
    `}
`;

const Container = styled.div`
    position: relative;
    height: auto;
    padding: 0 16px;
    margin-bottom: 68px;
    display: flex;
    justify-content: space-between;

    > div:first-of-type {
        padding-top: 6.25rem;
    }

    ${mediaQueries.lessThan('huge')`
        justify-content: center;
    `};

    ${mediaQueries.lessThan('small')`         
        padding: 48px 40px 0 40px;
        margin-bottom: 3rem;
        > div:first-of-type {
            padding-top: 1rem;
        }
    `}
`;

const ImgContainer = styled.div`
    width: auto;

    ${mediaQueries.lessThan('huge')`         
       display: none;
    `}
`;

const Form = styled.div`
    display: flex;

    ${mediaQueries.lessThan('small')`         
       flex-direction: column;
    `}
`;

const StyledTextField = styled(TextField)`
    width: 285px;
    margin-right: 8px;

    ${mediaQueries.lessThan('small')`         
        width: unset;
        margin-right: 0;
    `}
`;

const Spacer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    ${mediaQueries.greaterThan('small')`
        padding: 1.5rem 0;
    `}

    ${mediaQueries.lessThan('small')`
        padding: 1rem 0;
    `}
`;

const RatingBarWidget = styled(ReviewsIoRatingBarWidget)`
    position: relative;
`;

const UnstyledHeader = (props: HomeHeaderProps): JSX.Element => {
    const {
        className,
        title,
        subtitle,
        labelText,
        buttonText,
        countryPrefix,
        mobilePrefixes,
        cellphoneNumberLength,
        serviceName,
        serviceProviderName,
        operatorId,
        isLoggedIn,
    } = props;
    const loadingGiftUrl = '/static/landings/miscellanea/spinner-3.gif';

    const {t, lang} = props.i18n;

    const isPhone = () => {
        return serviceName === SERVICES.PHONE;
    };

    const isNauta = () => {
        return serviceName === SERVICES.INTERNET;
    };

    const isDataPlans = () => {
        return serviceName === SERVICES.DATA_PLANS;
    };

    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(getInitialValueForRechargeInputFromURL(window.location.href));
    }, []);

    function formatListString(list: number[], conjunction: string): string {
        if (!list || list.length === 0) {
            return '';
        }

        if (list.length === 1) {
            return `${list[0]}`;
        }

        return `${list.slice(0, -1).join(', ')} ${conjunction} ${
            list[list.length - 1]
        }`;
    }

    const validators =
        isPhone() || isDataPlans()
            ? [
                  Validators.required(t('validation:phone-number-required')),
                  Validators.phoneNumberLength(
                      t('validation:phone-number-too-short'),
                      t('validation:phone-number-too-long'),
                      cellphoneNumberLength,
                      cellphoneNumberLength
                  ),
              ]
            : [
                  //TODO: We need to change this from different non CU internet operators
                  Validators.required(t('validation:nauta-account-required')),
                  Validators.email(t('validation:nauta-account-must-end-with')),
              ];
    if ((isPhone() || isDataPlans()) && mobilePrefixes)
        validators.push(
            Validators.phoneNumberStartsWithList(
                `${t(
                    'validation:phone-number-first-digit-list'
                )} ${formatListString(mobilePrefixes, t('common:or'))}`,
                mobilePrefixes
            )
        );

    const [validate, error, setError] = useValidation(...validators);

    const handleSubmit = () => {
        const err = validate(value);
        if (err) {
            return true;
        }

        const recipient =
            serviceName === SERVICES.INTERNET
                ? {internet_user_id: value}
                : {
                      phone_number_prefix: `${countryPrefix}`,
                      phone_number_suffix: `${value}`,
                  };

        window.location.href = getConnectHomeLandingURLLink(
            window.location.href,
            isLoggedIn,
            recipient,
            serviceName,
            serviceProviderName,
            operatorId
        );
    };

    const handleFocus: FocusEventHandler<HTMLInputElement> = () => {
        if (error.error) {
            setError({error: false, errorText: ''});
        }
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setValue(`${event.target.value}`);
    };

    return (
        <header className={className}>
            <HeaderNoise noise={true} gradient={GradientType.HomeHeader} />
            <Wrapper>
                <Container>
                    <div>
                        <TitleSection>
                            <StyledH1>{title}</StyledH1>
                            <StyledH3>{subtitle}</StyledH3>
                        </TitleSection>
                        <Label>{labelText}</Label>
                        <Div>
                            <Form>
                                <StyledTextField
                                    inputType={isNauta() ? 'email' : 'number'}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    error={error.error}
                                    {...error}
                                    value={value}
                                    {...(isPhone() || isDataPlans()
                                        ? {
                                              startAdornment: (
                                                  <TextAdornment>
                                                      {`+${countryPrefix}`}
                                                  </TextAdornment>
                                              ),
                                          }
                                        : {})}
                                    {...(isNauta()
                                        ? {
                                              placeholder: 'user@nauta.com.cu',
                                          }
                                        : {})}
                                    data-testid="contact-input"
                                />
                                <SendButton
                                    onClickHandler={handleSubmit}
                                    data-testid="send-button"
                                    loadingGifUrl={loadingGiftUrl}
                                >
                                    {buttonText}
                                </SendButton>
                            </Form>
                        </Div>
                    </div>
                    <ImgContainer>
                        <PhoneHomeSvg />
                    </ImgContainer>
                </Container>
            </Wrapper>
            <Spacer>
                <AppScoreWidget appleScore={4.9} playScore={4.8} />
                <RatingBarWidget
                    lang={lang}
                    widgetId="WCzZgnHYaKOpD8xU"
                    storeName="www.fonoma.com"
                />
            </Spacer>
        </header>
    );
};

const StyledHomeHeader = styled(UnstyledHeader)`
    position: relative;
`;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
const HomeHeader = withAnalytics(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    withTranslation(StyledHomeHeader)
);

export {HomeHeader};
