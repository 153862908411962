import {styled} from 'styled-components';
import {mediaQueries} from '../../modules/common';

const DominoPlaceholder = styled.div`
    background-image: url(/static/landings/miscellanea/domino-desktop.webp);
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    margin-left: 48px;

    ${mediaQueries.lessThan('small')`   
        background-image: url(/static/landings/miscellanea/domino-mobile.webp);
        background-position: center; 
        background-size: unset;    
        margin-left: unset;

    `}
`;

export {DominoPlaceholder};
